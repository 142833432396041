import { create } from "zustand";

import { type LoggedUserData } from "@acme/types";

import { type UserAccess } from "~/app/_actions/user";

type Store = {
  currentAccess: UserAccess | null;
  loggedUser: LoggedUserData | null;
  setAccess: (access: UserAccess) => void;
  setLoggedUser: (user: LoggedUserData | null) => void;
};

export const useStore = create<Store>((set) => ({
  currentAccess: null,
  setAccess: (access) => set({ currentAccess: access }),
  loggedUser: null,
  setLoggedUser: (user) => set({ loggedUser: user }),
}));
