import z from "zod";

import { settingsReadShape } from "./settings";

export const orgWithSettings = z.object({
  id: z.string(),
  uid: z.string(),
  name: z.string().nullable(),
  settings: settingsReadShape.optional().nullable(),
  client: z
    .object({
      id: z.string(),
      name: z.string().nullable(),
      createdAt: z.coerce.date(),
      orgId: z.string(),
    })
    .optional(),
  clients: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        settings: settingsReadShape.optional().nullable(),
        type: z.enum(["PARTNER", "SITE"]),
      }),
    )
    .optional(),
});
