import { z } from "zod";

export const UploadStatusShape = z.enum([
  "PENDING",
  "NEW",
  "REJECTED",
  "DONE",
  "ARCHIVED",
]);
export type UploadStatusType = z.infer<typeof UploadStatusShape>;

export const UploadReadShape = z.object({
  id: z.string(),
  orgId: z.string(),
  patientId: z.string().optional().nullable(),
  status: UploadStatusShape,
});
