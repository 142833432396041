import { z } from "zod";

import { MeasurementReadSchema } from "./measurement";
import { UploadReadShape } from "./uploads";

export const PhotoBase = z.object({
  id: z.string(),
  url: z.string().nullable().optional(),
  key: z.string(),
  bucket: z.string(),
  measurements: z.array(MeasurementReadSchema).optional().nullable(),
});

export const PhotoWithMeasurements = z.object({
  id: z.string(),
  url: z.string().nullable().optional(),
  key: z.string(),
  bucket: z.string(),
  measurements: z.array(MeasurementReadSchema).optional().nullable(),
});

export const PhotoWithUpload = z.object({
  id: z.string(),
  url: z.string().nullable().optional(),
  key: z.string(),
  bucket: z.string(),
  upload: UploadReadShape,
});
