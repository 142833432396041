import { z } from "zod";

export const MeasurementReadSchema = z.object({
  id: z.string(),
  dp: z.number(),
  baser: z.number(),
  basel: z.number(),
  dnpr: z.number(),
  dnpl: z.number(),
});
