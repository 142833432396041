import { z } from "zod";

export const referenceSizeShape = z.coerce
  .number()
  .min(30)
  .max(600) // 60 cm
  .refine(
    (x) => x * 10 - Math.trunc(x * 10) < Number.EPSILON,
    ///TODO: i18n
    "1 decimal place only is allowed",
  );

export const referenceShape = z.object({
  id: z.string().optional(),
  name: z.string().min(2).max(50),
  description: z.string().max(200).nullable().optional(),
  size: referenceSizeShape,
});

// const referenceShape = z.object({
//   id: z.string().optional(),
//   name: z.string(),
//   description: z.string().optional().nullable(),
//   size: z
//     .number()
//     .min(30)
//     .max(60 * 10),
// });
