const returnToAllowed = [
  // regex matching this /patient/clwxvxyj1001xybg5vh69vrz4 and /patient/clwxvxyj1001xybg5vh69vrz4/

  /^\/patient\/[a-zA-Z0-9]{10,30}\/?$/,
];

export const canReturnTo = (path: string | null | undefined) => {
  if (!path) {
    return false;
  }
  return returnToAllowed.some((allowed) => allowed.test(path));
};

export const sanitizeReturnPath = (
  path: string | null | undefined,
  retDefault: string,
) => {
  if (!path) {
    return retDefault;
  }
  return returnToAllowed.some((allowed) => allowed.test(path))
    ? path
    : retDefault;
};
