import { z } from "zod";

const patientLinkShapeBase = z.object({
  ///TODO: Resolve problem with date in json data
  /// {"issues":[{"code":"invalid_type","expected":"date","received":"string","path":["validUntil"],"message":"Expected date, received string"}],"name":"ZodError"}
  validUntil: z.coerce.date(),
});

export const patientLinkShapeEdit = patientLinkShapeBase;

export const patientLinkShapeCreate = patientLinkShapeBase.extend({
  // uploads: UploadsShape.optional(),
  // patientLinks: PatientLinksShape.optional(),
  // emailValidation: PatientEmailValidation.optional().nullable(),
});

export const patientLinkReadShape = patientLinkShapeCreate.extend({
  id: z.string(),
  patientId: z.string(),
  token: z.string(),
  patient: z
    .object({
      id: z.string().optional(),
      orgId: z.string().optional(),
    })
    .optional(),
});
