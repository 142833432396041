import { z } from "zod";

export const loggedUserData = z.object({
  displayName: z.string(),
  email: z.string(),
  photoURL: z.string(),
  uid: z.string(),
});

export type LoggedUserData = z.infer<typeof loggedUserData>;

export const sessionLoginResult = z.object({
  status: z.string(),
  user: loggedUserData,
});

export type SessionLoginResult = z.infer<typeof sessionLoginResult>;
