import { z } from "zod";

export const partnerInputShape = z.object({
  name: z.string().min(2, {
    message: "Partner name must be at least 2 characters long.",
  }),
  email: z.string().email({
    message: "Patient email must be valid.",
  }),
  phone: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  address2: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  state: z.string().optional().nullable(),
  zip: z.string().optional().nullable(),
  // type: "PARTNER" | "SITE"
});
