import { createContext } from "react";

import { createContextHook } from "~/hooks/createContextHook";
import { Analytics } from "./AnalyticsType";

export const AnalyticsContext = createContext<Analytics | undefined>(undefined);

export const useAnalytics = createContextHook(
  AnalyticsContext,
  "AnalyticsContext",
);
