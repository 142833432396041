import { z } from "zod";

export const settingsInputShape = z.object({
  externalIdName1: z.string().optional().nullable(),
  externalIdName2: z.string().optional().nullable(),
});

export const settingsReadShape = settingsInputShape.extend({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
